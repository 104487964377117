import React from "react"
import { MediaContextProvider } from "./media"
import CartContext, { CartProvider } from '../data/cart-context'
import { AuthProvider } from "../data/auth-context"

export const Boot = ({ element }) => {
  return (
    <MediaContextProvider>
      <AuthProvider>
        <CartProvider>
          {element}
        </CartProvider>
      </AuthProvider>
    </MediaContextProvider>
  )
}
