import React from 'react';
import { Product } from './product-model'

export interface ExtendedProduct extends Product {
    quantity: number;
}

export interface CartContext {
    cartProducts: ExtendedProduct[];
    updateCartProducts: (product: Product, add: boolean, removeCompletely?: boolean, quantity?: number) => void;
}

const defaultCartState: CartContext = {
	cartProducts: [],
	updateCartProducts: () => [],
}
const CartContext = React.createContext(defaultCartState);

export const CartProvider = props => {
    const existing = typeof window !== 'undefined' && window.localStorage.getItem('cartProducts');
    const [cartProducts, updateCartProducts] = React.useState<ExtendedProduct[]>(existing ? JSON.parse(existing) : []);

    const handleUpdate = (product: Product, add: boolean, removeCompletely?: boolean, quantity?: number) => {
        const existingProduct = cartProducts.find(p => p.id === product.id);

        let newProds: ExtendedProduct[];

        if (existingProduct) {
            const curCartProds = [...cartProducts];

            if (removeCompletely) {
                curCartProds.splice(curCartProds.indexOf(existingProduct), 1);
            } else {
                if (quantity) {
                    existingProduct.quantity += quantity;
                    
                } else {
                    add ? existingProduct.quantity++ : existingProduct.quantity--;
                }
                curCartProds.splice(curCartProds.indexOf(existingProduct), 1, existingProduct);
            }

            newProds = curCartProds;
        } else {
            newProds = cartProducts.concat({...product, quantity: quantity});
        }
        
        updateCartProducts(newProds);
        typeof window !== 'undefined' && window.localStorage.setItem('cartProducts', JSON.stringify(newProds));
    }
  
    return (
      <CartContext.Provider value={{
        cartProducts,
        updateCartProducts: handleUpdate
      }}>
        {props.children}
      </CartContext.Provider>
    )
};

export default CartContext;