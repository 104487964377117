import React, { useEffect, useState } from 'react';
import { getCurrentUser } from '../services/auth-service';
import { AppUser } from './app-user-model';


export interface AuthContext {
    dbUser: AppUser;
    updateDbUser: (dbUser: AppUser) => void;
}

const defaultAuthState: AuthContext = {
	dbUser: null,
	updateDbUser: () => null,
}
const AuthContext = React.createContext(defaultAuthState);

export const AuthProvider = props => {
    const [dbUser, updateDbUser] = useState<AppUser>();

    const handleUpdate = (dbUser: AppUser) => {

        let newUser: AppUser = dbUser;
        updateDbUser(newUser);
    }
  
    return (
      <AuthContext.Provider value={{
        dbUser,
        updateDbUser: handleUpdate
      }}>
        {props.children}
      </AuthContext.Provider>
    )
};

export default AuthContext;